import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

Sentry.init({
	dsn: environment.sentryDsn,
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	tracePropagationTargets: [
		"https://metaquarium.xyz",
		"https://dev.metaquarium.xyz",
		"https://album.metaquarium.xyz",
		"https://y2k.metaquarium.xyz",
		"https://eth.metaquarium.xyz",
	],
	environment: environment.env,
	tracesSampleRate: 0.1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

// const amplifyConfig = {
// 	Auth: {
// 		identityPoolId: environment.cognitoIdentityPoolId,
// 		region: "us-west-1",
// 	},
// };
//Initialize Amplify
// Auth.configure(amplifyConfig);

// const analyticsConfig = {
// 	AWSPinpoint: {
// 		appId: environment.pinpointAppId,
// 		region: "us-west-2",
// 		mandatorySignIn: false,
// 	},
// };
// Initialize Analytics
// Analytics.configure(analyticsConfig);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
