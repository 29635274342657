/* eslint-disable indent */
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
// App Modules
import { CoreModule } from "@core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { environment } from "../environments/environment";
import { ArcadeModule } from "./_shared/arcade/arcade.module";
import { SafePipe } from './_shared/pipes/safe.pipe';

let notLocalEnv = environment.env !== "local";
notLocalEnv = false;

@NgModule({
	declarations: [AppComponent, SafePipe],
	imports: [
		BrowserModule,
		CoreModule,
		AppRoutingModule,
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: notLocalEnv,
			registrationStrategy: "registerWhenStable:30000",
		}),
		ArcadeModule,
	],
	bootstrap: [AppComponent],
	providers: [
		...(notLocalEnv
			? [
					{
						provide: ErrorHandler,
						useValue: Sentry.createErrorHandler({
							showDialog: false,
						}),
					},
					{
						provide: Sentry.TraceService,
						deps: [Router],
					},
					{
						provide: APP_INITIALIZER,
						useFactory: () => () => {},
						deps: [Sentry.TraceService],
						multi: true,
					},
				]
			: []),
	],
})
export class AppModule {}
