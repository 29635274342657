import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	// {
	// 	path: "gallery",
	// 	loadChildren: () => import("./gallery/gallery.module").then((m) => m.GalleryModule),
	// 	// canActivate: [DeveloperGuard],
	// },

	// {
	// 	path: "designer",
	// 	loadChildren: () => import("./designer/designer.module").then((m) => m.DesignerModule),
	// 	canActivate: [DeveloperGuard],
	// },

	// {
	// 	path: "mint",
	// 	loadChildren: () => import("./mint/mint.module").then((m) => m.MintModule),
	// },
	// {
	// 	path: "directory",
	// 	redirectTo: "gallery/directory",
	// },
	// {
	// 	path: "breeds",
	// 	redirectTo: "gallery/breeds",
	// },
	// {
	// 	path: "invite",
	// 	redirectTo: "gallery/invite",
	// },
	{
		path: "token/:id",
		loadChildren: () => import("./token/token.module").then((m) => m.TokenModule),
		data: {
			network: "ethereum",
		},
	},
	{
		path: "shop",
		loadComponent: () => import("./shop/shop.component").then((m) => m.ShopComponent),
	},
	{
		path: "404",
		loadComponent: () => import("./four-o-four/four-o-four.component").then((m) => m.FourOFourComponent),
	},
	{
    path: '',
    children: [
      {
        path: '',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
        pathMatch: 'full'
      },
      {
        path: ':id',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent)
      }
    ]
  },
	// {
	// 	path: "",
	// 	loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
	// },
	{
		path: "**",
		loadComponent: () => import("./four-o-four/four-o-four.component").then((m) => m.FourOFourComponent),
	},
];

// canActivate: [ProtectedGuard],

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
