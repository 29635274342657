import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, EventEmitter, HostListener, OnInit, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";

// ideally this would be in a service
import { Howl, Howler } from "howler";

import { AuthService } from "@core/auth/auth.service";
import { environment } from "@env/environment";
import Variables from "@style/scss-variables";

import { baseAlbum, bitcoinAlbum, dogeAlbum, ethereumAlbum, longbeachAlbum, polygonAlbum, solanaAlbum } from "./_shared/util/album";

import { ArcadeService } from "./_shared/arcade.service";

import { charlotteSpritesObject, charlotteSpritesUrl, consoleHeight, consoleHeightMobile, knobChainDetents, navHeight, visualizerVideos } from "./_shared/util/constants";
import { Device, FishInterface, MidiDevice, TraitValue } from "./_shared/util/types";

import { HashEllipsisPipe } from "./_shared/pipes/hash-ellipsis.pipe";
import { ExhibitService } from "./_shared/services/exhibit.service";
import { SupabaseService } from "./_shared/supabase.service";
import { getIPFSUrl } from "./_shared/util/helpers";

declare const window: any;
declare const document: any;

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	changeDetection: ChangeDetectionStrategy.Default,
	styleUrls: ["app.component.scss"],
	providers: [HashEllipsisPipe],
})
export class AppComponent implements AfterViewInit, OnInit {
	@Output() messageOutput = new EventEmitter<string>();
	videoRef: HTMLVideoElement;
	Variables = Variables;
	maximized = false;
	user?: any;
	connector: any = {
		account: null,
		chainId: null,
		isMetaMask: false,
		isBraveWallet: false,
	};
	env: string;
	consoleHeight = consoleHeight;

	indicators = {
		loading: { color: "#ad8cff", tip: "Loading", blink: true },
		error: { color: "#ff4500", tip: "Error", blink: true },
		web2: { color: "red", tip: "Web2 Detected" },
		web3: { color: "orange", tip: "Web3 Detected" },
		web3_wrong_network: { color: "red", tip: "Wrong Network Detected. Connect to " + environment.chainId + " Network" },
		auth: { color: "turquoise", tip: "Web 3 Signed In" },
	};
	controllerButtonAName = "A";
	controllerButtonBName = "B";
	indicatorColor = this.indicators.loading.color;
	indicatorTip = "";
	indicatorError = false;
	sound: Howl;
	ethereum: any;
	track = ethereumAlbum.tracks[0];
	loadingContent = true;
	loadingTrack = false;
	pendingAuth = false;
	click = false;
	VirtualButton?: HTMLElement;
	navHeight = navHeight;
	player = {
		track: ethereumAlbum.tracks[0],
		title: "...",
		volume: 0.3,
		isPlaying: false,
		isMuted: false,
		isInit: false,
		isPIP: false,
		isMinting: false,
		isMintSucess: false,
		playlist: undefined,
		viz: { ...visualizerVideos } as any,
		sprites: "assets/audio/fxSprites.mp3",
		midiEnabled: false,
		midiInit: false,
		format: "webm",
		backupFormat: "mp3",
		cabinet: false,
		soundId: null,
		visible: true,
	};
	charlotte: Howl;
	gamepad?: Gamepad;
	midi?: MidiDevice;
	consoleStatus = {
		web1: 1,
		web2: 1,
		web3: 2,
		member: 3,
	};
	consoleColumns = 12;
	consoleMinimized = false;
	sprites: Howl;
	displayMessage = "Welcome to the Metaquarium";
	flashMessage: string | null = null;
	device: Device;
	price: number | null = null;
	selectedOne: FishInterface = {
		animation_url: "",
		["3d"]: "",
		video_512: "",
		attributes: [
			// {
			// 	trait_type: this.exhibitService.symbol(),
			// 	value: "",
			// },
			// {
			// 	trait_type: "Established " + this.exhibitService.album().foundingDate,
			// 	value: "",
			// },
			// {
			// 	trait_type: "Headphones Recommended",
			// 	value: "",
			// },
			// {
			// 	trait_type: "Press Spacebar to Begin Experience",
			// 	value: "",
			// },
			// {
			// 	trait_type: "Some Fish Like it when you click on them",
			// 	value: "",
			// },
		],
		visible: false,
	};
	sceneDebug = false;
	time: Date;
	web2 = true;
	sceneLoaded = false;
	showLocations = false;
	logoCurrentLocation = 0;
	top8: any[] = [];
	pagerMessage = "";
	uploadedTrack: File | null = null;
	knobChainDetents = knobChainDetents;
	defaultStep: number;
	exhibit: string;
	exhibitsChanged = 0;
	public symbol: string | null = null;
	public isInitialLoad = true;
	isVideoMode = true;

	constructor(
		private authService: AuthService,
		private arcadeService: ArcadeService,
		private titleService: Title,
		private supabaseService: SupabaseService,
		private cdr: ChangeDetectorRef,
		private exhibitService: ExhibitService,
	) {
		this.env = environment.env;

		// temp fix
		// this.authService.listenWeb3Events();

		// |Make this an observable from arcade service
		this.device = this.arcadeService.getDevice();

		this.orientThings();

		// Initialize exhibit once
		this.exhibitService.initializeExhibit();

		// effect to update the exhibit
		effect(() => {
			const exhibit = this.exhibitService.currentExhibit();
			this.price = this.exhibitService.price();
			this.handleExhibitChange(exhibit);
		});

		this.loadPlayer();

		// temp fix
		// this.getCurrentUser();
		this.getConsoleColumns();
		this.refreshConnector();
		this.orientThings();

		// Subscribe to events
		this.arcadeService.selectedFish().subscribe((fish: FishInterface) => {
			// this.clearSelectedFish();
			this.swapSelectedFish(fish);
		});
		this.arcadeService.sceneEvent().subscribe((event: any) => {
			if (event === "debug-on") {
				this.sceneDebug = true;
			} else if (event === "debug-off") {
				this.sceneDebug = false;
			} else if (event === "escape") {
			} else if (event === "cabinet") {
				this.player.cabinet = !this.player.cabinet;
			} else if (event === "scene-loaded") {
				// this.sceneLoaded = true;
			} else if (event === "scene-unloaded") {
				// this.clearSelectedFish();
				this.sceneLoaded = false;
			} else if (event === "loader-hidden") {
				this.sceneLoaded = true;
				this.showLocations = true;

				if (this.sceneLoaded && this.sound.state() === "loaded" && !this.sound.isPlaying) {
					this.playSound();
					this.arcadeService.playerEvent().next("play");

					if (this.price) {
						// this.pushAttribute({trait_type: this.symbol as string, value: `$${this.price.toFixed(2)}`});
					}

					if (this.exhibitService.admissionPrice()) {
						// clear selected fish
						this.clearSelectedFish();
						this.pushAttribute({ trait_type: "Admission", value: `$${this.exhibitService.admissionPrice()!.toFixed(2)}` });
					}
					// special messaging for irl exhibits
					if (this.exhibitService.membershipPrice()) {
						
						this.pushAttribute({ trait_type: "Membership", value: `$${this.exhibitService.membershipPrice()!.toFixed(2)}` });
					}

					this.pushAttribute({ trait_type: "Established " + this.exhibitService.album().foundingDate, value: "" });
				}
			} else if (event === "selected-fish-unload") {
				this.clearSelectedFish();
			}
		});
		this.arcadeService.playerEvent().subscribe((event: any) => {
			if (event === "login") {
			} else if (event === "logout") {
				this.top8 = [];
			}

			if (event === "spacebar") {
				// if sound paused
				if (this.sound && !this.sound.playing()) {
					this.playSound();
				} else if (this.sound && this.sound.playing()) {
					this.sound.pause();
				}
			} else if (event === "key-up") {
				console.log("key-up");
				this.nextTrack();
				this.arcadeService.playerEvent().next("next");
			} else if (event === "key-down") {
				console.log("key-down");
				this.prevTrack();
				this.arcadeService.playerEvent().next("previous");
			} else if (event === "key-f") {
				this.arcadeService.playerEvent().next("full");
			}
		});
		this.arcadeService.pagerEvent().subscribe((event: any) => {
			setTimeout(() => {
				this.pagerMessage = "";
				this.pagerMessage = event;
			}, 100);
		});

		// set default step to exhibit service knob position
		this.defaultStep = this.exhibitService.knobPosition();

		// Subscribe to media mode changes from service
		this.arcadeService.mediaMode$.subscribe((mode) => {
			this.isVideoMode = mode !== "video";
		});

		// Subscribe to exhibit changes
		effect(() => {
			const exhibit = this.exhibitService.currentExhibit();
			if (exhibit) {
				// Default to showing trading view if chart is available
				const shouldShowTradingView = this.shouldShowChart(this.exhibitService.symbol());
				this.arcadeService.setMediaMode(shouldShowTradingView ? "trading" : "video");
			}
		});
	}

	ngOnInit() {
		// Initialize with current mode
		this.isVideoMode = this.arcadeService.getCurrentMediaMode() !== "video";
	}

	ngOnDestroy() {}

	async ngAfterViewInit() {
		if (this.device.isDesktop) {
			// this.midi = (await this.arcadeService.isMidiInit()) as MidiDevice;
			// this.arcadeService.initGamepads();
		}
	}

	getContentHeight() {
		if (this.maximized) {
			return window.innerHeight;
		} else {
			return window.innerHeight - consoleHeight - this.navHeight;
		}
	}

	@HostListener("fullscreenchange", ["$event"])
	onFullScreenChange(event: Event) {
		// if event is fullscreen
	}

	@HostListener("webkitfullscreenchange", ["$event"])
	onWebkitFullScreenChange(event: Event) {
		// if event is fullscreen
		const target = event.target as HTMLElement;
		// @ts-ignore
		if (document.webkitFullscreenElement === target) {
			if (this.videoRef) {
				this.videoRef.pause();
			}
		} else {
			if (this.videoRef) {
				this.videoRef.play();
			}
		}
	}

	// hostlistener on orientation change
	@HostListener("window:orientationchange", ["$event"])
	onOrientationChange(event: any) {
		if (event.target.orientation === 0) {
			this.device.orientation = "portrait-primary";
		} else if (event.target.orientation === undefined || event.target.orientation === 180) {
			this.device.orientation = "portrait-secondary";
		} else if (event.target.orientation === 90) {
			this.device.orientation = "landscape-primary";
		} else if (event.target.orientation === -90) {
			this.device.orientation = "landscape-secondary";
		}

		this.orientThings();
		this.arcadeService.playerEvent().next("orientationchange:" + this.device.orientation);
		if (this.sprites) {
			this.sprites.play("tropical_high");
		}
	}

	@HostListener("window:resize", ["$event"])
	async onResize(event: any) {
		// this.arcadeService.deviceDetector()
		this.orientThings();
		this.getConsoleColumns();
		this.getFinderColumns();
		this.getSwitchColumns();
		this.getPlayerColumns();
		this.getVizColumns();
		this.getKnobHeight();
		this.arcadeService.playerEvent().next("resize");
	}

	@HostListener("document:visibilitychange", ["$event"]) onVisibilityChange(event: any) {
		this.arcadeService.device.isTabVisible = document.visibilityState === "visible";
		if (this.arcadeService.device.isTabVisible && this.videoRef) {
			this.videoRef.play();
		} else if (!this.arcadeService.device.isTabVisible && this.videoRef) {
			this.videoRef.pause();
		}

		this.arcadeService.playerEvent().next("visibilitychange");
	}

	orientThings() {
		return new Promise((resolve, reject) => {
			if (this.device.orientation === "landscape-primary") {
				if (this.device.isMobile) {
					// this.player.cabinet = false;
					// this.arcadeService.playerEvent().next("cabinet:off");
				}
			} else if (this.device.orientation === "landscape-secondary") {
				if (this.device.isMobile) {
					// this.player.cabinet = false;
					// this.arcadeService.playerEvent().next("cabinet:off");
				}
			} else if (this.device.orientation === "portrait-primary") {
				// this.player.cabinet = true;
			} else if (this.device.orientation === "portrait-secondary") {
				// this.player.cabinet = true;
			}

			this.getConsoleColumns();
			return;
		});
	}

	getPlayerColumns() {
		let cols = 6;
		if (this.isFullscreen()) {
			cols = 3;
			return cols;
		}

		if (this.isPlayerInit()) {
			cols = 3;
		}

		if (this.device.isMobile) {
			cols = 12;
			if (this.device.orientation === "landscape-primary") {
				cols = 6;
				if (this.isPlayerInit()) {
					cols = 3;
				}
			} else if (this.device.orientation === "landscape-secondary") {
				cols = 6;
				if (this.isPlayerInit()) {
					cols = 3;
				}
			} else {
				cols = 8;
			}
		}

		if (this.device.isDesktop) {
			cols = 3;
			if (window.innerWidth < Variables.breakpointTablet) {
				cols = 6;
			}

			if (window.innerWidth < Variables.breakpointMobile) {
				cols = 12;
			}
		}

		return cols;
	}

	getVizColumns() {
		let cols = 0;
		if (this.isFullscreen()) {
			cols = 3;
			return cols;
		}

		if (this.isPlayerInit()) {
			cols = 3;
		}

		if (this.device.isMobile) {
			cols = 0;
			if (this.device.orientation === "landscape-primary") {
				if (this.isPlayerInit()) {
					cols = 3;
				}
			} else if (this.device.orientation === "landscape-secondary") {
				if (this.isPlayerInit()) {
					cols = 3;
				}
			} else {
				cols = 0;
			}
		}

		if (this.device.isDesktop) {
			cols = 3;
			if (window.innerWidth < Variables.breakpointTablet) {
				cols = 0;
			}
		}

		// if (this.isPlaying()) {
		// 	return 6;
		// }

		return cols;
	}

	getFinderColumns() {
		if (this.isFullscreen()) {
			return 3;
		}

		if (this.device.isMobile) {
			if (this.device.orientation === "landscape-primary") {
				return 3;
			} else if (this.device.orientation === "landscape-secondary") {
				return 3;
			} else if (this.device.orientation === "portrait-secondary") {
				return 3;
			} else {
				return 0;
			}
		} else if (this.device.isTablet) {
			if (this.isPlaying()) {
				return 3;
			} else {
				return 3;
			}
		}

		if (this.device.isDesktop) {
			if (window.innerWidth < Variables.breakpointTablet) {
				return 0;
			} else if (window.innerWidth < Variables.breakpointMobile) {
				return 0;
			}
		}

		return 3;
	}

	getSwitchColumns() {
		if (this.isFullscreen()) {
			return 3;
		}

		if (this.device.isMobile) {
			if (this.device.orientation === "landscape-primary") {
				return 3;
			} else if (this.device.orientation === "landscape-secondary") {
				return 3;
			} else if (this.device.orientation === "portrait-secondary") {
				return 3;
			} else {
				return 8;
			}
		} else if (this.device.isTablet) {
			if (this.isPlaying()) {
				return 3;
			} else {
				return 3;
			}
		} else if (this.device.isDesktop) {
			if (window.innerWidth < Variables.breakpointTablet) {
				return 3;
			}

			if (window.innerWidth < Variables.breakpointMobile) {
				return 0;
			}
		}

		return 2;
	}

	getDialColumns() {
		if (this.isFullscreen()) {
			return 0;
		}

		if (this.device.isMobile) {
			if (this.device.orientation === "landscape-primary") {
				return 3;
			} else if (this.device.orientation === "landscape-secondary") {
				return 3;
			} else if (this.device.orientation === "portrait-secondary") {
				return 3;
			} else {
				return 4;
			}
		} else if (this.device.isTablet) {
			if (this.isPlaying()) {
				return 3;
			} else {
				return 3;
			}
		} else if (this.device.isDesktop) {
			if (window.innerWidth < Variables.breakpointTablet) {
				return 3;
			}

			if (window.innerWidth < Variables.breakpointMobile) {
				return 0;
			}
		}

		return 3;
	}

	getConsoleHeight() {
		if (this.device.isMobile && this.device.orientation.startsWith("landscape")) {
			return "0px";
		} else if (this.device.isMobile && this.device.orientation.startsWith("portrait")) {
			return consoleHeightMobile + "px";
		} else {
			return consoleHeight + "px";
		}
	}

	getConsoleColumns() {
		return this.consoleColumns;
	}

	isPlaying() {
		if (this.sound && this.sound.playing()) {
			return true;
		} else {
			return false;
		}
	}

	isPlayerInit() {
		return this.player.isInit;
	}

	loadPlayer() {
		this.sprites = new Howl({
			src: [this.player.sprites],
			volume: 0.5,
			sprite: {
				subtle_drum_alert: [0, 4310],
				subtle_toggle: [5000, 1714.2857142857144],
				switch_toggle: [8000, 857.1428571428577],
				tiny_click: [10000, 1714.2857142857135],
				tiny_toggle: [13000, 1714.2857142857135],
				tropical_high: [16000, 2000],
				weighty_bell: [19000, 6000],
			},
		});

		this.charlotte = new Howl({
			src: [charlotteSpritesUrl],
			volume: 0.7,
			sprite: charlotteSpritesObject,
		});
	}

	isWeb3() {
		return window.ethereum || false;
	}

	isWeb2() {
		return !this.isWeb3();
	}

	isAuthenticated() {
		if (this.user) {
			return true;
		}

		return false;
	}

	isProd() {
		if (this.env === "production") {
			return true;
		} else {
			return false;
		}
	}

	isDev() {
		if (this.env === "dev") {
			return true;
		} else {
			return false;
		}
	}

	isLocal() {
		if (this.env === "local") {
			return true;
		} else {
			return false;
		}
	}

	isDebug() {
		return this.sceneDebug;
	}

	isStandalone() {
		return this.device.isStandalone;
	}

	isMinting() {
		return false;
	}

	getDisplayMessage() {
		let displayText = this.displayMessage;
		if (this.flashMessage) {
			displayText = this.flashMessage;
		}

		return displayText;
	}

	async playSound() {
		// if first play, play welcome sound after play button is clicked
		if (this.exhibitsChanged <= 1) {
			setTimeout(() => {
				// this.charlotte.play("welcome_" + this.exhibit);
			}, 1000);
		}
		if (!this.sound) {
			this.loadSound(this.player.track);
		}

		if (!this.sound.playing()) {
			const seek = this.sound.seek();

			if (seek > 0) {
				this.sound.play(this.player.soundId);
			} else {
				this.player.soundId = this.sound.play();
			}
		}

		this.player.isInit = true;
	}

	loadSound(_track) {
		Howler.autoUnlock = true;

		let html5 = false;

		if (_track.howl) {
			_track.howl = null;
			// this.sound = _track.howl;
			// this.sound.autoplay = true;
			// this.sound.load();
			// return;
		}

		if (this.device.hasSpatialAudio && this.player.format === "flac") {
			_track.webm = _track.flac;
			html5 = false;
		}

		// let poolSize = 5;
		// if (this.device.isMobile) {
		// 	poolSize = 3;
		// }

		this.sound = _track.howl = new Howl({
			src: [_track.webm, _track.mp3],
			title: _track.title,
			preload: true,
			mute: this.player.isMuted,
			volume: this.player.volume,
			html5: html5,
			onplay: () => {
				this.player.track = _track;
				// Art Gallery - SHow Album Name
				// this.displayMessage = _track.title;
				this.displayMessage = this.exhibit + " - " + _track.title;
				const title = _track.title + " - Metaquarium";
				this.titleService.setTitle(title);

				if ("mediaSession" in navigator) {
					navigator.mediaSession.metadata = new MediaMetadata({
						title: _track.title,
						artist: "Metaquarium",
						album: "Ethereum",
						artwork: [
							{
								sizes: "640x640",
								src: "../assets/images/album-640.png",
							},
							{
								sizes: "300x300",
								src: "../assets/images/album-300.png",
							},
							{
								sizes: "64x64",
								src: "../assets/images/album-64.png",
							},
						],
					});
					navigator.mediaSession.setActionHandler("play", () => {
						this.playSound();
					});
					navigator.mediaSession.setActionHandler("pause", () => {
						this.sound.pause();
					});
					navigator.mediaSession.setActionHandler("stop", () => {
						this.sound.pause();
					});
					navigator.mediaSession.setActionHandler("previoustrack", () => {
						this.prevTrack();
						this.arcadeService.playerEvent().next("previous");
					});
					navigator.mediaSession.setActionHandler("nexttrack", () => {
						this.nextTrack();
						this.arcadeService.playerEvent().next("next");
					});
				}

				this.videoRef = document.getElementById("videoPlayer") as HTMLVideoElement;
				if (this.videoRef && this.arcadeService.device.isTabVisible) {
					this.videoRef.play();
				}

				// Analytics.record({ name: "playerEvent", attributes: { state: "track-started", track: _track.title, track_number: _track.id } });
				this.arcadeService.playerEvent().next("track-onplay");
			},
			onload: (event) => {
				this.displayMessage = this.exhibit + " exhibit";
				// Analytics.record({ name: "playerEvent", attributes: { state: "track-loaded", track: _track.title, track_number: _track.id } });
				if (this.arcadeService) {
					this.arcadeService.playerEvent().next("track-onload");
				}
			},
			unlock: (event) => {
				// this.displayMessage = "ethereum";
				// Analytics.record({ name: "playerEvent", attributes: { state: "track-unlocked", track: _track.title, track_number: _track.id } });
				this.arcadeService.playerEvent().next("track-unlock");
			},
			loaderror: (event) => {
				this.displayMessage = "Error";
				// Analytics.record({ name: "playerEvent", attributes: { state: "track-load-error", track: _track.title, track_number: _track.id } });
				this.arcadeService.playerEvent().next("track-loaderror");
			},
			onend: () => {
				this.displayMessage = "...";
				this.nextTrack();
				// Analytics.record({ name: "playerEvent", attributes: { state: "track-ended", track: _track.title, track_number: _track.id } });

				this.arcadeService.playerEvent().next("track-onend");
			},
			onpause: () => {
				this.displayMessage = "Paused";
				this.arcadeService.playerEvent().next("track-onpause");
				if (this.videoRef) {
					this.videoRef.pause();
				}
			},
			onplayerror: () => {
				this.displayMessage = "Error";

				this.sound.once("unlock", () => {
					this.sound.play();
				});
				// Analytics.record({ name: "playerEvent", attributes: { state: "track-error", track: _track.title, track_number: _track.id } });
				this.arcadeService.playerEvent().next("track-onplayerror");
			},
			onmute: () => {
				this.arcadeService.playerEvent().next("track-onmute");
			},
		});
	}

	toggleFormat() {
		if (this.player.format === "webm") {
			this.arcadeService.setStereoWave();
			this.player.format = "flac";
		} else if (this.player.format === "flac") {
			this.player.format = "webm";
			this.arcadeService.stopStereoWave();
		}

		this.loadSound(this.player.track);
	}

	isSpatialEnabled() {
		if (this.player.format === "flac") {
			return true;
		} else {
			return false;
		}
	}

	mouseAction(_type: string) {
		if (_type === "enter") {
			// this.arcadeService.playInstrumentWithString("choir_aahs");
		} else if (_type === "leave") {
			// this.arcadeService.stopInstrument("choir_aahs");
		}
	}

	loadTrack(_track: any) {
		this.sound.skip(_track);
	}

	nextTrack() {
		this.soundSkip("next");
		setTimeout(() => {
			this.charlotte.play("going_up");
		}, 500);
	}

	prevTrack() {
		this.soundSkip("prev");
		setTimeout(() => {
			this.charlotte.play("going_down");
		}, 500);
	}

	selectTrack(_track: any) {
		// this.sound.skip(_track);
	}

	async soundSkip(_direction: string) {
		// If the sound is still loading, wait a few seconds and try again.
		if (this.loadingTrack) {
			return;
		}

		// One Timers
		this.player.isInit = true;
		// this.player.cabinet = false;

		if (this.sound) {
			this.loadingTrack = true;
			// get album of exhibit
			let playlist;

			if (this.exhibit === "dogecoin") {
				playlist = dogeAlbum.tracks;
			} else if (this.exhibit === "base") {
				playlist = baseAlbum.tracks;
			} else if (this.exhibit === "solana") {
				playlist = solanaAlbum.tracks;
			} else if (this.exhibit === "bitcoin") {
				playlist = bitcoinAlbum.tracks;
			} else if (this.exhibit === "polygon") {
				playlist = polygonAlbum.tracks;
			} else if (this.exhibit === "ethereum") {
				playlist = ethereumAlbum.tracks;
			} else if (this.exhibit === "longbeach") {
				playlist = longbeachAlbum.tracks;
			} else {
				playlist = ethereumAlbum.tracks;
			}

			const currentTrackIndex = this.player.track.id;

			let nextTrackIndex = currentTrackIndex;
			if (_direction == "next") {
				nextTrackIndex++;
				this.flashMessageonDisplay("going up", 2500);
			} else if (_direction == "prev") {
				nextTrackIndex--;
				this.flashMessageonDisplay("going down", 2500);
			}

			if (nextTrackIndex < 0) {
				nextTrackIndex = 1;
			} else if (nextTrackIndex == 0) {
				nextTrackIndex = playlist.length;
			} else if (nextTrackIndex > playlist.length) {
				nextTrackIndex = 1;
			}

			const nextTrack = playlist.filter((track) => track.id == nextTrackIndex)[0];

			if (!this.uploadedTrack?.arrayBuffer) {
				this.sound.stop();
				this.sound.unload();
				await this.loadSound(nextTrack);
			}

			// wait for 3 seconds
			await new Promise((resolve) => setTimeout(resolve, 4500));

			await this.playSound();

			this.loadingTrack = false;
		}
	}

	async uploadTrack() {
		const file = document.createElement("input");
		file.type = "file";
		file.accept = ".mp3,.glb";
		file.style.display = "none";
		document.body.appendChild(file);

		file.onchange = (e: any) => {
			const f = e.target.files[0];
			if (!f) return;

			const reader = new FileReader();

			if (f.name.endsWith(".glb")) {
				reader.onload = async (e: any) => {
					// Create blob with proper MIME type
					const blob = new Blob([e.target.result], {
						type: "model/gltf-binary",
					});

					// Create object URL
					const blobUrl = URL.createObjectURL(blob);

					const customFish: FishInterface = {
						"3d": blobUrl,
						video_512: "",
						visible: true,
						attributes: [
							{
								trait_type: "",
								value: f.name,
							},
						],
						// Add this to help with cleanup
						modelFile: blob,
					};

					// Load the model
					this.arcadeService.selectedFish().next(customFish);
					this.flashMessageonDisplay(`Loaded model: ${f.name}`, 3000);
				};
				reader.readAsArrayBuffer(f);
			} else if (f.type.startsWith("audio/")) {
				// Existing MP3 handling
				this.uploadedTrack = f;
				reader.onload = async (e: any) => {
					const buffer = e.target.result;
					const track = {
						title: f.name,
						webm: buffer,
						mp3: buffer,
					};
					this.sound.unload();
					this.loadSound(track);
					this.playSound();
				};
				reader.readAsDataURL(f);
			}
		};

		file.click();
		document.body.removeChild(file);
	}

	togglePlaylist() {
		this.sound.togglePlaylist();
	}

	toggleMute() {
		this.sound.mute(!this.sound._muted);
		this.player.isMuted = !this.player.isMuted;
	}

	async buttonClick(_type: string, _event: any) {
		// this.device.isMobile && !typeof document.ontouchstart === "undefined"

		if (_type === "play") {
			await this.sprites.play("tiny_click");
			this.playSound();
			this.arcadeService.playerEvent().next(_type);
		}

		if (_type === "pause") {
			this.sprites.play("tiny_click");
			this.sound.pause();

			this.arcadeService.playerEvent().next(_type);
		}

		if (_type === "next") {
			this.sprites.play("subtle_toggle");
			this.nextTrack();
			this.arcadeService.playerEvent().next(_type);
		}

		if (_type === "previous") {
			this.sprites.play("subtle_toggle");
			this.prevTrack();
			this.arcadeService.playerEvent().next(_type);
		}

		if (_type === "load") {
			this.sprites.play("subtle_drum_alert");
			// load track from uploaded file
			this.uploadTrack();
			this.arcadeService.playerEvent().next(_type);
		}
	}

	async toggleClick(_type: string, _event: any) {
		// if event is not a button, do nothing
		if (_event.target.hasAttribute("disabled") || _event.target.tagName !== "BUTTON") {
			return;
		}

		this.arcadeService.playerEvent().next(_type);

		if (_type === "full") {
			this.sprites.play("tiny_toggle");
		}

		if (_type === "online") {
			this.sprites.play("tiny_tingle");
			if (this.isAuthenticated()) {
				this.logout();
			} else {
				this.login();
			}
		}

		if (_type === "vr") {
			this.sprites.play("weighty_bell");
		}

		// if (_type === "mint") {
		// 	this.sprites.play("subtle_toggle");
		// 	this.mint();
		// }

		if (_type === "debug") {
			if (this.sceneDebug === false) {
				this.sceneDebug = true;
			} else {
				this.sceneDebug = false;
			}

			this.sprites.play("switch_toggle");
		}

		if (_type === "mute") {
			this.toggleMute();
			this.sprites.play("subtle_drum_alert");
		}

		if (_type === "spatial") {
			this.toggleFormat();
			this.sprites.play("tropical_high");
		}

		if (_type === "midi") {
			await this.toggleMidi();
			this.sprites.play("weighty_bell");
		}

		if (_type === "stick") {
			this.sprites.play("weighty_bell");
			this.arcadeService.initGamepad();
		}

		if (_type === "pip") {
			this.sprites.play("switch_toggle");
			this.arcadeService.togglePictureInPicture();
		}

		if (_type === "eject") {
			this.sprites.play("switch_toggle");
			this.arcadeService.eject();
		}

		if (_type === "shop") {
			this.sprites.play("switch_toggle");
			this.arcadeService.shop();
		}

		if (_type === "gpt") {
			this.sprites.play("switch_toggle");
			this.arcadeService.chatGpt();
		}

		if (_type === "wasd") {
			this.sprites.play("switch_toggle");
			this.arcadeService.wasd();
		}

		// Analytics.record({ name: "uiEvent", attributes: { type: _type, event: "switchToggle" } });
	}

	knobChange(_event: string) {
		// lowercase and remove spaces
		const event = _event.toLowerCase().replace(/\s+/g, "");

		// Stop all current sounds before changing exhibit
		this.stopAllSounds();

		if (!this.loadingContent) {
			this.sprites.play("weighty_bell");
		}

		this.exhibit = event;
		this.arcadeService.setExhibit(event);

		Promise.resolve().then(() => {
			this.loadingContent = true;
			this.displayMessage = "Loading " + event + " exhibit...";

			// Only play welcome sound if sound is already playing
			if (this.exhibitsChanged > 0) {
				this.charlotte.play("teleporting");
				setTimeout(() => {
					if (
						event === "ethereum" ||
						event === "polygon" ||
						event === "base" ||
						event === "bitcoin" ||
						event === "solana" ||
						event === "dogecoin" ||
						event === "longbeach" ||
						event === "montereybay"
					) {
						this.charlotte.play("welcome_" + event);
					} else {
						this.charlotte.play("welcome");
					}
				}, 4000);
			}
			this.exhibitsChanged++;

			setTimeout(() => {
				this.loadingContent = false;
				this.displayMessage = event + " exhibit";
				this.cdr.markForCheck();
			}, 2000);
		});

		// Notify after sounds are handled
		setTimeout(() => {
			this.arcadeService.playerEvent().next("exhibit-" + event);
		}, 100);
	}

	getKnobHeight() {
		const variation = 32;
		let width = window.innerWidth / 4 - variation - 10;
		if (width > this.consoleHeight - variation) {
			width = this.consoleHeight - variation;
		}
		if (this.device.isMobile) {
			width = this.consoleHeight - variation - 100;
		}

		return width;
	}

	async getCurrentUser() {
		this.authService.currentUser.subscribe(async (x) => {
			if (!x) {
				this.user = null;
				this.top8 = [];
				return;
			}

			this.user = x;
			if (this.user.tokens.length > 0) {
				let tokens = this.user.tokens;
				if (this.user.tokens.length > 8) {
					tokens = this.user.tokens.slice(0, 8);
				}

				this.top8 = [];

				tokens.forEach((token: any) => {
					this.top8.push(token.metadata_s3);
				});
			} else {
				this.top8 = [];
			}

			if (this.connector !== null) {
				if (this.connector.chainId !== environment.chainId) {
					this.indicatorColor = this.indicators.web3_wrong_network.color;
					this.indicatorTip = this.indicators.web3_wrong_network.tip;
					this.web2 = false;
					this.indicatorError = true;
					return;
				}

				if (this.user) {
					this.indicatorColor = this.indicators.auth.color;
					this.indicatorTip = this.indicators.auth.tip;
					this.web2 = false;
				} else {
					this.indicatorColor = this.indicators.web3.color;
					this.indicatorTip = this.indicators.web3.tip;
					this.web2 = false;
				}
			} else {
				this.indicatorColor = this.indicators.web2.color;
				this.indicatorTip = this.indicators.web2.tip;
				this.web2 = true;
			}
		});
	}

	async login(provider: "metamask" | "walletconnect" = "metamask") {
		try {
			this.pendingAuth = true;
			this.indicatorColor = this.indicators.loading.color;
			this.indicatorTip = this.indicators.loading.tip;
			const user = await this.authService.login(provider);
			if (user.moralis_provider_id) {
				this.indicatorColor = this.indicators.auth.color;
				this.indicatorTip = this.indicators.auth.tip;
			}

			this.getCurrentUser();

			this.pendingAuth = false;
		} catch (error: any) {
			this.refreshConnector();
			this.pendingAuth = false;
		}
	}

	async logout() {
		await this.authService.logout();
		this.indicatorColor = this.indicators.web3.color;
		this.indicatorTip = this.indicators.web3.tip;
	}

	isStickEnabled() {
		return this.arcadeService.device.hasStick;
	}

	isFullscreen() {
		try {
			if (document.fullscreenElement) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	isPIPEnabled() {
		return this.arcadeService.player.isPIP;
	}

	flashMessageonDisplay(message: string, duration = 2500) {
		// If Key is pressed down
		if (duration === 0) {
			this.flashMessage = message;
			return;
		}

		// If Existing Message, Wait 1 Second
		if (this.flashMessage) {
			// this.flashMessage = message;
			setTimeout(() => {
				this.flashMessage = message;
			}, 1000);
			return;
		} else {
			// / Handle a Press Event Until Lifted
			this.flashMessage = message;
			setTimeout(() => {
				this.flashMessage = null;
			}, duration);
		}
	}

	isMidiEnabled() {
		if (this.midi && this.midi.state === "connected") {
			return true;
		}

		return false;
	}

	async toggleMidi() {
		if (this.device.isMobile || this.device.isTablet || this.device.isStandalone) {
			return;
		}

		if (this.midi) {
			this.midi = undefined;
		} else {
			this.midi = (await this.arcadeService.isMidiInit()) as MidiDevice;
		}
	}

	isGptEnabled() {
		return false;
	}

	isWasdEnabled() {
		return this.device.isWasd;
	}

	fishFinderClick() {
		const player = this.selectedOne;

		this.arcadeService.playerEvent().next("fishfinder-" + player.name);
		// find player in threejs scene
	}

	refreshConnector() {
		this.connector = this.authService.getConnector();

		if (this.connector !== null) {
			if (this.connector.chainId) {
				if (this.connector.chainId !== environment.chainId) {
					this.indicatorColor = this.indicators.web3_wrong_network.color;
					this.indicatorTip = this.indicators.web3_wrong_network.tip;
					this.web2 = false;
					this.indicatorError = true;
				}
			} else {
				this.indicatorColor = this.indicators.web3.color;
				this.indicatorTip = this.indicators.web3.tip;
				this.web2 = false;
			}

			if (this.user) {
				this.indicatorColor = this.indicators.auth.color;
				this.indicatorTip = this.indicators.auth.tip;
				this.web2 = false;
			}
		} else {
			this.indicatorColor = this.indicators.web2.color;
			this.indicatorTip = this.indicators.web2.tip;
			this.web2 = true;
		}
	}


	swapSelectedFish(fish) {
		if (fish["3d"]) {
			this.selectedOne = fish;
			this.selectedOne.visible = true;
		}

		// if selected one image starts with ipfs:// then get the gateway url
		if (this.selectedOne.image && this.selectedOne.image.startsWith("ipfs://")) {
			this.selectedOne.image = getIPFSUrl(this.selectedOne.image);
		}

		// animation_url
		// if (this.selectedOne.animation_url && this.selectedOne.animation_url.startsWith("ipfs://")) {
		// 	this.selectedOne.animation_url = getIPFSUrl(this.selectedOne.animation_url);
		// }
		// transcode video
		if (this.selectedOne.video_512 && this.selectedOne.video_512.startsWith("ipfs://")) {
			this.selectedOne.video_512 = getIPFSUrl(this.selectedOne.video_512);
		}

		const videoRef = document.getElementById("videoPlayer") as HTMLVideoElement;

		// Analytics.record({
		// 	name: "selectedFish",
		// 	attributes: { name: this.selectedOne.name as any, token_id: this.selectedOne.token_id as any, track_number: this.track.id.toString(), user: this.user?.id || "" },
		// });

		this.selectedOne.visible = true;
		// change media visualizer to fish.video if it exists
		if (this.selectedOne.video_512) {
			this.player.viz.poster = this.selectedOne.image as string;
			this.player.viz.src = this.selectedOne.video_512 as string;

			if (videoRef) {
				videoRef.load();
				videoRef.oncanplaythrough = () => {
					videoRef.play();
				};

				videoRef.onprogress = (_progress) => {
					// count how many bytes have been loaded as a fraction of the total bytes
					const percent = (_progress.loaded / _progress.total) * 100;
				};
			}
		} else {
			this.player.viz = { ...visualizerVideos } as any;
			console.log(this.selectedOne);
			console.log("no video");
		}
	}

	clearSelectedFish() {
		console.log("clearSelectedFish");
		this.selectedOne = {
			animation_url: "",
			["3d"]: "",
			video_512: "",
			attributes: [],
			visible: false,
		};

		
		// this.player.viz.poster = "";
		// this.player.viz.src = "";
		// this.player.viz.poster = visualizerVideos.poster;
		if (this.player.viz.src !== "visualizerVideos.src") {
			this.player.viz.src = visualizerVideos.src;
			this.player.viz.poster = visualizerVideos.poster;
		}
	}

	private pushAttribute(attribute: TraitValue) {
		if (attribute) {
			this.selectedOne.attributes?.push(attribute);
		}
	}

	private stopAllSounds(): void {
		// Stop the main track if playing
		if (this.sound && this.sound.playing()) {
			this.sound.stop();
		}

		// Stop any sprites
		if (this.sprites) {
			this.sprites.stop();
		}
	}

	// Add this method to handle hover events
	onUploadHover(isHovering: boolean) {
		if (isHovering) {
			this.flashMessage = "Import MP3/GLB";
		} else {
			this.flashMessage = null;
		}
	}

	handleExhibitChange(exhibit: string) {
		this.player.track = this.exhibitService.album()!.tracks[0];
		this.exhibit = exhibit;
		this.symbol = this.exhibitService.symbol();
		this.price = this.exhibitService.price();

		// Set initial load to false after first exhibit change
		this.isInitialLoad = false;

		this.loadSound(this.player.track);
	}

	public getTradingViewChartUrl(symbol: string): string {
		const symbolToId = {
			ETH: "BINANCE:ETHUSDT",
			BTC: "BINANCE:BTCUSDT",
			DOGE: "BINANCE:DOGEUSDT",
			SOL: "BINANCE:SOLUSDT",
			MATIC: "COINBASE:MATICUSDT",
			BASE: "COINBASE:ETH-USD",
		};

		const cleanSymbol = symbol?.toUpperCase()?.trim() || "ETH";
		const tvSymbol = symbolToId[cleanSymbol] || symbolToId[cleanSymbol.replace("POLYGON", "MATIC")] || "BINANCE:ETHUSDT";

		// Simplified overrides for a more minimal look
		const overrides = encodeURIComponent(
			JSON.stringify({
				"mainSeriesProperties.candleStyle.upColor": "#00ff00",
				"mainSeriesProperties.candleStyle.downColor": "#006600",
				"mainSeriesProperties.candleStyle.borderUpColor": "#00ff00",
				"mainSeriesProperties.candleStyle.borderDownColor": "#006600",
				"mainSeriesProperties.candleStyle.wickUpColor": "#00ff00",
				"mainSeriesProperties.candleStyle.wickDownColor": "#006600",
				"mainSeriesProperties.areaStyle.linecolor": "#00ff00",
				"paneProperties.background": "rgba(0, 0, 0, 0)",
				"paneProperties.backgroundType": "transparent",
				"paneProperties.vertGridProperties.color": "rgba(0, 17, 0, 0.1)",
				"paneProperties.horzGridProperties.color": "rgba(0, 17, 0, 0.1)",
				"scalesProperties.textColor": "rgba(0, 255, 0, 0.5)",
				"scalesProperties.lineColor": "rgba(0, 102, 0, 0.2)",
			}),
		);

		// More minimal URL parameters
		// dont let size be greater than 191
		return (
			`https://s.tradingview.com/widgetembed/?` +
			`symbol=${tvSymbol}&` +
			`interval=D&` +
			`hidesidetoolbar=1&` +
			`hide_side_toolbar=1&` +
			`hide_legend=1&` +
			`hide_volume=1&` +
			`symboledit=0&` +
			`save_image=0&` +
			`studies=%5B%5D&` +
			`theme=dark&` +
			`style=3&` +
			`timezone=exchange&` +
			`studies_overrides=%7B%7D&` +
			`overrides=${overrides}&` +
			`enabled_features=%5B%5D&` +
			`disabled_features=%5B%22header_widget%22,%22left_toolbar%22,%22footer_widget%22,%22timeframes_toolbar%22,%22legend_widget%22,%22volume_force_overlay%22,%22symbol_info%22,%22symbol_search_hot_key%22,%22border_around_the_chart%22,%22main_series_scale_menu%22,%22legend_context_menu%22%5D&` +
			`locale=en&` +
			`backgroundColor=rgba(0,0,0,0)&` +
			`isTransparent=true&` +
			`autosize=1&` +
			`hide_top_toolbar=0&` +
			`allow_symbol_change=0&` +
			`save_image=0`
		);
	}

	public shouldShowChart(symbol: string | null): boolean {
		if (!symbol) return false;
		// Video-only exhibits should return false
		return !["LBC", "BASE", "MB"].includes(symbol);
	}

	// Add this helper method
	public getVideoOpacity(symbol: string | null): number {
		// Video is more visible when trading view isn't available or when video mode is active
		if (!this.shouldShowChart(symbol) || this.isVideoMode) {
			return 1;
		}
		return 0.6; // Dimmed when trading view is visible
	}

	toggleMediaMode(state: boolean) {
		if (this.isToggleDisabled()) return;
		this.arcadeService.setMediaMode(state ? "trading" : "video");
	}

	// Helper method to determine if video should be primary
	shouldShowVideo(symbol: string | null): boolean {
		if (!symbol) return true;
		return ["LBC", "BASE", "MB"].includes(symbol);
	}

	// Add this helper method to determine if toggle should be disabled
	public isToggleDisabled(): boolean {
		return !this.shouldShowChart(this.symbol);
	}
	0;
}
