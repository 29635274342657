<div
	class="app-wrapper"
	id="app"
	[ngClass]="isFullscreen() ? 'maximized' : isStandalone() ? 'standalone' : ''"
>
	<!-- Nav / Header -->
	<div class="main-nav" [class.takeover]="!isPlaying()">
		<div class="nav-primary">
			<arcade-logo
				[showLocations]="showLocations"
				[location]="this.player.track.id"
				[animation]="true"
				[glowBorder]="true"
				[top8]="top8"
				[page]="pagerMessage"
				(locationPressed)="selectTrack($event)"
				(mouseAction)="mouseAction($event)"
			></arcade-logo>
		</div>
	</div>

	<!-- App / Router Outlet -->
	<arcade-tile
		class="content"
		id="content"
		[optimize]="false"
		[style.maxHeight]="getContentHeight()"
		[class.blur]="!isPlaying()"
		[transparent]="false"
		[hideFooter]="true"
		[glowBorder]="false"
		[ngClass]="{
			'no-cabinet': !player.cabinet,
			'no-console': getConsoleHeight() === '0px'
		}"
	>
		<router-outlet></router-outlet>
	</arcade-tile>

	<!-- Console / -->
	<div
		class="arcade-console"
		*ngIf="getConsoleColumns() > 0"
		[class.blur]="!isPlaying()"
	>
		<mat-grid-list
			class="arcade-grid-list cols-{{ getConsoleColumns() }}"
			style="max-height: {{ getConsoleHeight() }}"
			cols="{{ getConsoleColumns() }}"
			rowHeight="{{ getConsoleHeight() }}"
		>
			<!-- Fish Finder -->
			<mat-grid-tile
				class="arcade-grid-tile fish-console"
				[rowspan]="1"
				[colspan]="getFinderColumns()"
			>
				<arcade-tile
					class="fish-finder"
					[centerTitle]="true"
					[title]="'Fish Finder'"
					[hideFooter]="true"
					[transparent]="isFullscreen()"
					(click)="fishFinderClick()"
				>
					<div class="selected-one">
						<div class="three-d-wrapper">
							<arcade-three-d
								*ngIf="selectedOne['3d']"
								[src]="selectedOne['3d']"
								[name]="'FishFinder'"
								[shadowIntensity]="0"
								[glass]="true"
								[glassOnly]="false"
								[size]="consoleHeight - 80"
								[reveal]="'manual'"
								[cameraTarget]=""
								[cameraControls]="true"
								[disableTap]="true"
								[disablePan]="true"
								[autoRotate]="true"
								[interactionPrompt]="'none'"
							></arcade-three-d>
						</div>
						<div class="arcade-list fish-info">
							<ul>
								<!-- <li *ngIf="selectedOne.name">prompt://</li> -->
								<li class="fish-name" *ngIf="selectedOne.name">
									{{ selectedOne.name }}
								</li>
								<!-- loop over attributes array and display the value of each key \value pair
								 -->
								<li *ngFor="let attribute of selectedOne.attributes">
									{{ attribute.trait_type }} {{ attribute.value }}
								</li>
							</ul>
						</div>
					</div>
				</arcade-tile></mat-grid-tile
			>

			<!-- Media Visualizer -->
			<mat-grid-tile
				*ngIf="getVizColumns() > 0"
				[rowspan]="1"
				[colspan]="getVizColumns()"
			>
				<arcade-tile
					class="viz-tile"
					[title]="'Media Visualizer'"
					[hideFooter]="device.isStandalone || isFullscreen()"
					[transparent]="isFullscreen()"
				>
					<video
						class="video-player"
						id="videoPlayer"
						#videoPlayer
						[poster]="player.viz.poster"
						[src]="player.viz.src"
						playsinline
						autoplay
						preload="auto"
						muted
						loadstart
						loop
					></video>
					<div
						class="controller-buttons"
						*ngIf="selectedOne.animation_url && !isProd"
					>
						<arcade
							[size]="'50px'"
							[name]="controllerButtonAName"
							[horizontal]="true"
							(click)="controllerButtonPress(controllerButtonAName, $event)"
						></arcade>
						<arcade
							[size]="'50px'"
							[name]="controllerButtonBName"
							[horizontal]="true"
							(click)="controllerButtonPress(controllerButtonBName, $event)"
						></arcade>
					</div>
				</arcade-tile>
			</mat-grid-tile>

			<!-- Arcade-Player -->

			<mat-grid-tile
				class="player-console player-stacked"
				[rowspan]="1"
				[colspan]="getPlayerColumns()"
			>
				<arcade-tile
					class="arcade-music-player"
					[verticalContent]="false"
					[title]="'Media Player'"
					[hideFooter]="true"
				>
					<arcade-display
						class="player-display"
						[fontSize]="28"
						[title]="getDisplayMessage()"
						[alignment]="'center'"
						[valignment]="'center'"
					>
					</arcade-display>
					<div class="arcade-music-buttons">
						<button
							class="arcade-music-button button-green"
							[disabled]="sound.playing()"
							(click)="buttonClick('play', $event)"
							mat-flat-button
						>
							<mat-icon
								aria-hidden="false"
								aria-label="Play"
								fontIcon="play_arrow"
							></mat-icon>
						</button>
						<button
							class="arcade-music-button button-orange"
							[disabled]="!sound.playing()"
							(click)="buttonClick('pause', $event)"
							mat-flat-button
						>
							<mat-icon
								aria-hidden="false"
								aria-label="Pause"
								fontIcon="pause"
							></mat-icon>
						</button>
						<button
							class="arcade-music-button button-red button-previous"
							[disabled]="!sound.playing()"
							(click)="buttonClick('previous', $event)"
							mat-flat-button
						>
							<mat-icon
								aria-hidden="false"
								aria-label="Down"
								fontIcon="download"
							></mat-icon>
						</button>
						<button
							class="arcade-music-button button-blue button-next"
							[disabled]="!sound.playing()"
							(click)="buttonClick('next', $event)"
							mat-flat-button
						>
							<mat-icon
								aria-hidden="false"
								aria-label="Up"
								fontIcon="file_upload"
							></mat-icon>
						</button>
						<button
							class="arcade-music-button button-blue button-next"
							[disabled]=""
							(click)="buttonClick('load', $event)"
							mat-flat-button
						>
							<mat-icon
								aria-hidden="false"
								aria-label="Eject"
								fontIcon="eject"
							></mat-icon>
						</button>
					</div>
				</arcade-tile>

				<arcade-tile
					class="arcade-console-switches"
					[scrollSnap]="false"
					[hideFooter]="true"
					[transparent]="isFullscreen()"
					[title]="'Media Controls'"
				>
					<arcade-switch
						[title]="'Online'"
						[on]="!web2"
						*ngIf="!isProd()"
						[indicatorOnTip]="indicatorTip"
						[indicatorOffTip]="indicatorTip"
						[color]="indicatorColor"
						[error]="false"
						[loading]="pendingAuth"
						(click)="toggleClick('online', $event)"
					></arcade-switch>
					<arcade-switch
						*ngIf="user"
						[title]="'Mint'"
						[loading]="player.isMinting"
						[on]="player.isMintSucess"
						[color]="'#ff6347'"
						(click)="toggleClick('mint', $event)"
					></arcade-switch>
					<arcade-switch
						*ngIf="!isProd()"
						[title]="'Debug'"
						[on]="isDebug()"
						[color]="'#dbff00'"
						(click)="toggleClick('debug', $event)"
					></arcade-switch>
					<arcade-switch
						class="max-toggle"
						[title]="'Max'"
						[on]="isFullscreen()"
						[color]="'#00e785'"
						(click)="toggleClick('full', $event)"
					></arcade-switch>
					<arcade-switch
						[title]="'Mute'"
						[on]="this.player.isMuted"
						[color]="'#fba600'"
						(click)="toggleClick('mute', $event)"
					></arcade-switch>
					<arcade-switch
						*ngIf="
							device.hasDocumentPIP &&
							device.deviceInfo.browser === 'Chrome' &&
							device.isDesktop
						"
						[title]="'PIP'"
						[disabled]="!device.hasDocumentPIP"
						[indicatorOnTip]="'Picture In Picture On'"
						[indicatorOffTip]="
							device.hasDocumentPIP
								? 'Picture In Picture Off'
								: 'Picture In Picture Not Supported By Browser'
						"
						[on]="isPIPEnabled()"
						[color]="Variables.synthwave9"
						(click)="toggleClick('pip', $event)"
					></arcade-switch>

					<arcade-switch
						*ngIf="!isProd()"
						[disabled]="!device.hasSpatialAudio"
						[title]="'Spatial'"
						[on]="isSpatialEnabled()"
						[color]="Variables.synthwave3"
						(click)="toggleClick('spatial', $event)"
					></arcade-switch>
					<arcade-switch
						[disabled]="isProd() || (!user && !device.hasVR)"
						[title]="'VR'"
						[on]="false"
						[color]="'#fba600'"
						(click)="toggleClick('vr', $event)"
					></arcade-switch>

					<arcade-switch
						*ngIf="!isProd() || user"
						[title]="'MIDI'"
						[indicatorOnTip]="midi ? midi.name : undefined"
						[indicatorOffTip]="'Not Detected'"
						[on]="isMidiEnabled()"
						[color]="Variables.synthwave2"
						(click)="toggleClick('midi', $event)"
					></arcade-switch>
					<arcade-switch
						*ngIf="!isProd()"
						[title]="'Stick'"
						[indicatorOnTip]="gamepad ? gamepad.id : undefined"
						[indicatorOffTip]="'Not Detected'"
						[on]="isStickEnabled()"
						[color]="Variables.synthwave1"
						(click)="toggleClick('stick', $event)"
					></arcade-switch>
					<arcade-switch
						[title]="'About'"
						[indicatorOffTip]="'Website'"
						[color]="Variables.synthwave3"
						(click)="toggleClick('eject', $event)"
					></arcade-switch>

					<arcade-switch
						[title]="'Shop'"
						[indicatorOffTip]="'Take Me Home'"
						[color]="Variables.synthwave1"
						(click)="toggleClick('shop', $event)"
					></arcade-switch>
					<arcade-switch
						*ngIf="!isProd()"
						[title]="'GPT'"
						[on]="isGptEnabled()"
						[indicatorOffTip]="'Oracle'"
						[color]="Variables.synthwave4"
						(click)="toggleClick('gpt', $event)"
					></arcade-switch>
					<arcade-switch
						*ngIf="!isProd()"
						[title]="'WASD'"
						[on]="isWasdEnabled()"
						[indicatorOffTip]="'Keyboard Commands'"
						[color]="Variables.synthwave4"
						(click)="toggleClick('wasd', $event)"
					></arcade-switch>
				</arcade-tile>
			</mat-grid-tile>

			<!-- Arcade Dial -->
			<mat-grid-tile
				class="arcade-grid-tile dial-console"
				[rowspan]="1"
				[colspan]="getDialColumns()"
			>
				<arcade-tile
					class="dial-tile"
					[centerTitle]="true"
					[centerContent]="true"
					[title]="'Exhibit'"
					[hideFooter]="false"
					[transparent]="isFullscreen()"
				>
					<arcade-knob
						[size]="getKnobHeight()"
						(stepChange)="knobChange($event)"
						[defaultStep]="defaultStep"
						[detents]="knobChainDetents"
					>
					</arcade-knob>
				</arcade-tile>
			</mat-grid-tile>
		</mat-grid-list>
	</div>
</div>
