import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	{
		path: "token/:id",
		loadChildren: () => import("./token/token.module").then((m) => m.TokenModule),
		data: {
			network: "ethereum",
		},
	},
	{
		path: "shop",
		loadComponent: () => import("./shop/shop.component").then((m) => m.ShopComponent),
	},
	{
		path: "404",
		loadComponent: () => import("./four-o-four/four-o-four.component").then((m) => m.FourOFourComponent),
	},
	{
    path: '',
    children: [
      {
        path: '',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
        pathMatch: 'full'
      },
      {
        path: ':id',
        loadComponent: () => import('./home/home.component').then(m => m.HomeComponent)
      }
    ]
  },
	{
		path: "**",
		loadComponent: () => import("./four-o-four/four-o-four.component").then((m) => m.FourOFourComponent),
	},
];

// canActivate: [ProtectedGuard],

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
