import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// description: this pipe is used to bypass security trust for resource url
// this is used to load the tradingview chart

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 